import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
  oauthRequestApiRef,
  microsoftAuthApiRef,
  fetchApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { UserSettingsStorage } from '@backstage/plugin-user-settings';
import {
  OpenApiDefinitionWidget,
  apiDocsConfigRef,
  defaultDefinitionWidgets,
} from '@backstage/plugin-api-docs';
import { ApiEntity } from '@backstage/catalog-model';

import {
  additionalConfigApiRef,
  AdditionalConfigApiImpl,
} from './apis/additionalConfigApi';
import { azureDevOpsMSAuthApiRef } from './apis/azureDevOpsMSAuthApi';
import { MicrosoftAuth } from '@backstage/core-app-api';
import { UserIcon } from '@backstage/core-components';
import {
  axiosInstanceProviderApiRef,
  AxiosInstanceProviderApiImpl,
} from './apis/axiosInstanceApi';
import {
  externalDocsApiRef,
  ExternalDocsApiImpl,
} from './apis/externalDocsApi';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { sonarQubeApiRef } from '@backstage/plugin-sonarqube-react';
import {
  capabilityTaxonomyApiRef,
  CapabilityTaxonomyApiImpl,
} from './apis/capabilityTaxonomyApi';
import {
  swTemplateCustomApiRef,
  SwTemplateCustomApiImpl,
} from './apis/swTemplateCustomApi';
import { promotionApiRef, PromotionApiImpl } from './apis/promotionApi';
import { adminApiRef, AdminApiImpl } from './apis/adminApi';
import { subscriberApiRef, SubscriberApiImpl } from './apis/subscriberApi';
import { mockServiceApiRef, MockServiceApiImpl } from './apis/mockServiceApi';
import {
  entityConversionApiRef,
  EntityConversionApiImpl,
} from './apis/entityConversionApi';
import { feedbackApiRef, FeedbackApiImpl } from './apis/feedbackApis';
import {
  GamificationApiImpl,
  gamificationApiRef,
} from './apis/gamificationApis';
import { DashboardApiImpl, dashboardApiRef } from './apis/dashboardApi';
import { userApiRef, UserApiImpl } from './apis/userApi';
import { registerApiRef, RegisterApisImpl } from './apis/registerApis';
import { FetchApiImpl } from './apis/fetchApi';
import { toolboxApiRef, ToolboxApiImpl } from './apis/toolboxApi';
import {
  informationExchangeApiRef,
  InformationExchangeApiImpl,
} from './apis/informationExchange';
import { DataInsightsApiRef, DataInsightsApiImpl } from './apis/dataInsights';
import {
  notificationApiRef,
  NotificationApiImpl,
} from './apis/notificationApi';
import { towerInfoApiRef, TowerInfoApiImpl } from './apis/towerInfo';
import {
  VisualRegressionApiImpl,
  visualRegressionApiRef,
} from './apis/visualRegression';
import { NoOpErrorApiImpl, noOpErrorApiRef } from './apis/noopErrorApi';
import { HelpApiImpl, helpApiRef } from './apis/helpPageContent';
import { LeanixApiImpl, leanixApiRef } from './apis/leanixApi';
import { OpenAIApiImpl, openaiApiRef } from './apis/openAI';
import { ReleaseNoteApiImpl, releaseNoteApiRef } from './apis/releaseNotes';
import { UserProfileApiImpl, userProfileApiRef } from './apis/userProfileApi';
import { ExtendedSonarQubeClient } from './apis/sonarQubeApi';
import { SnykApiClient, snykApiRef } from './apis/snykApi';
import { WhatsNewImpl, whatsNewApiRef } from './apis/whatsNew';
import { PocHubApiImpl, pocHubApiRef } from './apis/pocHubApi';
import { VideoBytesApiRef, VideoBytesImpl } from './apis/videoBytes';
import { PromptApiImpl, promptApiRef } from './apis/promptManagementApi';
import {
  ToolsProvisioningApiImpl,
  toolsProvisioningApiRef,
} from './apis/toolsProvisioningApi';
import { object } from 'yup';

export const apis: AnyApiFactory[] = [
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: fetchApiRef,
    deps: { identityApi: identityApiRef },
    factory: ({ identityApi }) => new FetchApiImpl(identityApi),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: additionalConfigApiRef,
    deps: { axiosInstanceProviderApi: axiosInstanceProviderApiRef },
    factory: ({ axiosInstanceProviderApi }) =>
      new AdditionalConfigApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: azureDevOpsMSAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      MicrosoftAuth.create({
        discoveryApi,
        oauthRequestApi,
        configApi,
        provider: {
          id: 'oauth2',
          title: 'Microsoft Azure Devops',
          icon: UserIcon, // change it to suitable icon
        },
        defaultScopes: [],
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: axiosInstanceProviderApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      microsoftAuthApi: microsoftAuthApiRef,
    },
    factory: ({ discoveryApi, identityApi, microsoftAuthApi }) =>
      new AxiosInstanceProviderApiImpl(
        discoveryApi,
        identityApi,
        microsoftAuthApi,
      ),
  }),
  createApiFactory({
    api: externalDocsApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
      catalogApi: catalogApiRef,
    },
    factory: ({ axiosInstanceProviderApi, catalogApi }) =>
      new ExternalDocsApiImpl(axiosInstanceProviderApi, catalogApi),
  }),
  createApiFactory({
    api: capabilityTaxonomyApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
      catalogApi: catalogApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ axiosInstanceProviderApi, catalogApi, identityApi }) =>
      new CapabilityTaxonomyApiImpl(
        axiosInstanceProviderApi,
        catalogApi,
        identityApi,
      ),
  }),
  createApiFactory({
    api: swTemplateCustomApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new SwTemplateCustomApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: promotionApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new PromotionApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: adminApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new AdminApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: subscriberApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new SubscriberApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: mockServiceApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new MockServiceApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: entityConversionApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new EntityConversionApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: feedbackApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new FeedbackApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: gamificationApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new GamificationApiImpl(axiosInstanceProviderApi),
  }),

  createApiFactory({
    api: dashboardApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new DashboardApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: userApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new UserApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: registerApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new RegisterApisImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: toolboxApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new ToolboxApiImpl(axiosInstanceProviderApi),
  }),

  createApiFactory({
    api: informationExchangeApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new InformationExchangeApiImpl(axiosInstanceProviderApi),
  }),

  createApiFactory({
    api: DataInsightsApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new DataInsightsApiImpl(axiosInstanceProviderApi),
  }),

  createApiFactory({
    api: notificationApiRef,
    deps: {},
    factory: () => new NotificationApiImpl(),
  }),
  createApiFactory({
    api: towerInfoApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new TowerInfoApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: storageApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      errorApi: noOpErrorApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef,
    },
    factory: deps => UserSettingsStorage.create(deps),
  }),
  createApiFactory({
    api: noOpErrorApiRef,
    deps: {},
    factory: () => new NoOpErrorApiImpl(),
  }),
  createApiFactory({
    api: visualRegressionApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new VisualRegressionApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: leanixApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new LeanixApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: helpApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new HelpApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: openaiApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new OpenAIApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: releaseNoteApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new ReleaseNoteApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: userProfileApiRef,
    deps: {},
    factory: () => new UserProfileApiImpl(),
  }),
  createApiFactory({
    api: sonarQubeApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) => {
      return new ExtendedSonarQubeClient({ discoveryApi, identityApi });
    },
  }),
  createApiFactory({
    api: snykApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      configApi: configApiRef,
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ discoveryApi, configApi, axiosInstanceProviderApi }) =>
      new SnykApiClient({ discoveryApi, configApi, axiosInstanceProviderApi }),
  }),
  createApiFactory({
    api: whatsNewApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new WhatsNewImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: pocHubApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new PocHubApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: VideoBytesApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new VideoBytesImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: promptApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new PromptApiImpl(axiosInstanceProviderApi),
  }),
  createApiFactory({
    api: toolsProvisioningApiRef,
    deps: {
      axiosInstanceProviderApi: axiosInstanceProviderApiRef,
    },
    factory: ({ axiosInstanceProviderApi }) =>
      new ToolsProvisioningApiImpl(axiosInstanceProviderApi),
  }),
  //swagger ui interceptor
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      const supportedSubmitMethodsWSO2 = [];

      const defaultWidget = defaultDefinitionWidgets();
      const newWidgets = {
          ...defaultWidget[0],
          type: 'wso2',
          component: (definition: any) => (
            // @ts-ignore
            <OpenApiDefinitionWidget
              definition={definition}
              supportedSubmitMethods={supportedSubmitMethodsWSO2}
          />)
      }
      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          console.log(apiEntity.metadata.assetstore, 'apiEntity.metadata.assetstore')
          if (apiEntity.metadata.assetstore === 'wso2') return newWidgets;
          return defaultWidget.find(d => d.type === apiEntity.spec.type);
        }
      };

    }
  })
];
