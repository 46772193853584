import { Theme } from '@material-ui/core';

export const performanceTestStyles = (theme: Theme) => {
  return {
    addConfigContainer: {
      height: '68vh',
    },

    addConfigHeader: {
      padding: `0px ${theme.spacing(3)}px`,
    },

    addConfigFormContainer: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },

    addConfigFooter: {
      border: `1px solid ${theme.palette.border}`,
    },

    /* Performace Test - Service - Ends here*/

    /* Styles for Manage Config Page */
    configDropdown: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
    },
    emptyPaper: {
      height: `${theme.spacing(65)}px`,
      backgroundColor: theme.palette.background.secondary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    emptyPageText: {
      color: theme.palette.text.tertiary,
      fontSize: `${theme.spacing(2.5)}px`,
    },
    emptyPageAction: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: `${theme.spacing(2)}px`,
    },
    manageConfigHeaderButtons: {
      display: 'flex',
      justifyContent: 'end',
    },
    manageConfigHeaderActionButton: {
      marginLeft: `${theme.spacing(1.5)}px`,
      fontSize: `${theme.spacing(2)}px`,
      textTransform: 'none',
    },
    updatedTime: {
      color: theme.palette.text.tertiary,
      margin: `${theme.spacing(1)}px`,
    },
    actionButtonInTableCell: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    actionButtonRun: {
      padding: '0px',
    },
    configTableRow: {
      backgroundColor: theme.palette.background.paper + ' !important',
      border: `1px solid ${theme.palette.border}`,
    },
    stopIcon: {
      color: '#A03530',
    },
    iconInMoreMenu: {
      marginRight: `${theme.spacing(1)}px`,
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      backgroundColor: theme.palette.background.tertiary + ' !important',
    },
    tableHeadCell: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    tableBodyCell: {
      color: theme.palette.text.secondary,
    },
    colorRunexecuting: {
      color: theme.palette.warning.main,
    },
    colorRunended: {
      color: theme.palette.success.main,
    },
    colorRunfailed: {
      color: theme.palette.error.main,
    },
    colorRunqueued: {
      color: theme.palette.warning.main,
    },
    colorRunprovisioning: {
      color: theme.palette.warning.main,
    },
    colorRunstarted: {
      color: theme.palette.colors.sage,
    },
    configStatus: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    emptyBoxContainer: {
      padding: '0px 24px',
    },

    /* Runs page styling */

    runsPageContainer: {
      padding: `0px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      alignItems: 'end',
    },
    dateTimeTableCell: {
      display: 'flex',
      gap: `${theme.spacing(1.2)}px`,
    },
    runsPageActionButtons: {
      display: 'flex',
      gap: `${theme.spacing(1.2)}px`,
    },

    /* Report Page styling */
    reportPageIconButton: {
      backgroundColor: theme.palette.colors.green,
      borderRadius: `${theme.spacing(1)}px`,
      padding: '5px',
      '& span': {
        fontSize: '16px',
      },
      '& span > svg:nth-of-type(1)': {
        marginRight: '5px',
      },
    },
    reportPageButton: {
      marginRight: `${theme.spacing(1.2)}px`,
    },
    reportPageIconButtonOutlined: {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      padding: '5px',
      '& span': {
        fontSize: '16px',
        color: theme.palette.primary.main,
      },
      '& span > svg:nth-of-type(1)': {
        marginRight: '5px',
      },
    },
    reportPaper: {
      marginTop: `${theme.spacing(2)}px`,
    },
    reportBox: {
      padding: `${theme.spacing(2)}px`,
      border: `1px solid #959595`,
      borderRadius: '4px',
      flex: '1 0 21%',
      '& p:nth-of-type(1)': {
        color: `${theme.palette.text.secondary} 65%`,
        fontSize: '14px',
      },
      '& p:nth-of-type(2)': {
        color: theme.palette.text.secondary,
        fontSize: '14px',
      },
    },
    reportBoxContainer: {
      gap: `${theme.spacing(1.2)}px`,
    },
    dashboardIframe: {
      width: '100%',
      minHeight: '500px',
    },
  } as const;
};
