import {
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useStyles } from '../../../styles';
import BackButton from '../../../../utils/backButton';
import { HelpComponent } from '../../../../utils/helpComponent';
import HELP_URL from '../../../../utils/helpLinkConstant';
import EmptyConfigBox from './components/EmptyConfigBox';
import Search from '@material-ui/icons/Search';
import RunsTable, { IRunsTableProps } from './components/RunsTable';
import { usePTRuns } from './hooks/usePTRuns';
import { useFilteredRuns } from './hooks/useFilteredRuns';
import { ITPContext, TPContext } from '../../../providers/TPProvider';
import Add from '@material-ui/icons/Add';
import Refresh from '@material-ui/icons/Refresh';

const RunsPage = ({ isToolAdmin }: { isToolAdmin: boolean }) => {
  const classes = useStyles();

  const { projectId } = useParams();
  const navigate = useNavigate();

  const navigateToAddConfig = () => {
    navigate('../../manage/add-config');
  };
  const { runs, stopRun, downloadRunResults, loading, refreshRuns } = usePTRuns(
    projectId!,
    true,
  );
  const [searchText, setSearchText] = useState<string>('');
  const [sort, setSort] = useState<IRunsTableProps['sort']>('descending');

  const { filteredRuns } = useFilteredRuns(runs, searchText, sort);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  // For Flow availble related check
  const { apmInfo }: ITPContext = useContext(TPContext);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.flexSpacebetween}
        >
          <BackButton />
          <div className={classes.runsPageActionButtons}>
            <Button
              variant="text"
              color="primary"
              onClick={() => refreshRuns()}
              disabled={!apmInfo?.flows || filteredRuns?.length === 0}
              className={`${classes.padding1} ${classes.minWidthAuto}`}
            >
              <Refresh />
            </Button>
            {isToolAdmin ? (
              <Button
                variant="contained"
                color="primary"
                onClick={navigateToAddConfig}
                className={classes.sentenceCaseText}
                disabled={!apmInfo?.flows}
              >
                <Add />
                Add Config
              </Button>
            ) : null}
            <HelpComponent helpUrl={HELP_URL} />
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.runsPageContainer} spacing={2}>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Typography variant="h5">Runs</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <TextField
            variant="outlined"
            type="search"
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search runs"
            fullWidth
            InputProps={{ endAdornment: !searchText && <Search /> }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.emptyBoxContainer} spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {loading && <LinearProgress />}
          {filteredRuns?.length === 0 ? (
            !loading && (
              <EmptyConfigBox
                navigatePath="../../manage/add-config"
                flows={apmInfo?.flows}
                isToolAdmin={isToolAdmin}
                text="runs"
              />
            )
          ) : (
            <RunsTable
              onDownloadRunResults={(immutableId: string) =>
                downloadRunResults(immutableId)
              }
              onStopRun={(immutableId: string) => {
                stopRun(immutableId).then(() => refreshRuns());
              }}
              runsData={filteredRuns}
              sort={sort}
              onSort={setSort}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default RunsPage;
